var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-4 coints-page"},[_c('AddTreatCampaign',{ref:"addTreatCampaignFlyout",on:{"save":function($event){return _vm.onFlyoutSave()}}}),_c('div',{staticClass:"section"},[_c('div',{staticClass:"section__title mb-1 d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"section__text"},[_vm._v("Active Campaigns")]),_c('v-btn',{staticClass:"ma-2 white--text p-4",attrs:{"color":"green darken-1"},on:{"click":function($event){return _vm.onOpenFlyout()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")]),_vm._v(" ADD NEW ")],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.dataSource,"loading":_vm.loading,"options":_vm.pagination,"server-items-length":_vm.total,"footer-props":{
        'items-per-page-options': [10, 20, 50, 100]
      }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.startsAt))]),_c('td',[_vm._v(_vm._s(item.endsAt))]),_c('td',[_vm._v(_vm._s(item.country ? item.country.name : ""))]),_c('td',[_vm._v(_vm._s(_vm.getCityNames(item.cities)))]),_c('td',[_vm._v(_vm._s(_vm.getProductNames(item.products)))]),_c('td',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.onOpenFlyout(item)}}},[_vm._v(" "+_vm._s(_vm.$t("common.edit"))+" ")]),_c('v-list-item',{on:{"click":function($event){return _vm.onDeleteCampaign(item)}}},[_vm._v(" "+_vm._s(_vm.$t("common.delete"))+" ")])],1)],1)],1)])]}}])})],1),_c('div',{staticClass:"section mt-4"},[_c('div',{staticClass:"section__title mb-1 d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"section__text"},[_vm._v(_vm._s(_vm.$t("coins.archive")))])]),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.dataSourceArchive,"loading":_vm.loadingArchive,"options":_vm.paginationArchive,"server-items-length":_vm.totalArchive,"footer-props":{
        'items-per-page-options': [10, 20, 50, 100]
      }},on:{"update:options":function($event){_vm.paginationArchive=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.startsAt))]),_c('td',[_vm._v(_vm._s(item.endsAt))]),_c('td',[_vm._v(_vm._s(item.country ? item.country.name : ""))]),_c('td',[_vm._v(_vm._s(_vm.getCityNames(item.cities)))]),_c('td',[_vm._v(_vm._s(_vm.getProductNames(item.products)))]),_c('td',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.onOpenFlyout(item)}}},[_vm._v(" "+_vm._s(_vm.$t("common.edit"))+" ")]),_c('v-list-item',{on:{"click":function($event){return _vm.onDeleteArchive(item)}}},[_vm._v(" "+_vm._s(_vm.$t("common.delete"))+" ")])],1)],1)],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }