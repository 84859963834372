import UrlHandlerApiService from "@/core/services/url-handler.service";
import Vue from "vue";

const TreatCampaignService = {
  URL: "",
  async init() {
    this.URL = await UrlHandlerApiService.getBaseUrl();
    this.ADMIN_URL = process.env.VUE_APP_API_BASE;
  },

  CAMPAIGNS_API: "api/promo/v1/NewUserTreat/campaigns",
  CAMPAIGN_API: id => `api/promo/v1/NewUserTreat/campaigns/${id}`,
  PRODUCTS: "api/shop/product",

  getCampaigns(params) {
    const config = {
      params
    };
    return Vue.axios.get(this.URL + this.CAMPAIGNS_API, config).catch(error => {
      throw new Error(`[KT] TreatCampaign ${error}`);
    });
  },
  createCampaign(body) {
    return Vue.axios.post(this.URL + this.CAMPAIGNS_API, body).catch(error => {
      throw new Error(`[KT] TreatCampaign ${error}`);
    });
  },
  updateCampaign(body) {
    return Vue.axios.patch(this.URL + this.CAMPAIGN_API(body?.id), body).catch(error => {
      throw new Error(`[KT] TreatCampaign ${error}`);
    });
  },
  deleteCampaign(id) {
    return Vue.axios.delete(this.URL + this.CAMPAIGN_API(id)).catch(error => {
      throw new Error(`[KT] TreatCampaign ${error}`);
    });
  },

  getAllProducts(params) {
    const config = {
      params
    };
    return Vue.axios.get(this.URL + this.PRODUCTS, config).catch(error => {
      throw new Error(`[KT] All products ${error}`);
    });
  }
};

export default TreatCampaignService;
