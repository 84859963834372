import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import AddTreatCampaign from "./offcanvas/add-treat-campaign.vue";
import { EXCHANGE_RATE_LABELS } from "./coin.enums";
import { VALIDATORS } from "@/common/validators";
import TreatCampaignService from "@/core/services/api.service.treat-campaign";
import { formatUTCToLocal } from "@/common/date-format";
//import { ACTIVE_HEADERS, ARCHIVE_HEADERS } from "./treat-campaign";

export default {
  name: "treat-campaign",
  components: { AddTreatCampaign },
  data() {
    return {
      exchangeRageLabels: EXCHANGE_RATE_LABELS,
      isInitialized: false,
      generalRules: {},
      dataSource: [],
      dataSourceArchive: [],
      loading: false,
      loadingArchive: false,
      rules: {
        required: VALIDATORS.required
      },
      total: 0,
      totalArchive: 0,
      pagination: {},
      paginationArchive: {}
    };
  },
  watch: {
    pagination: {
      handler() {
        if (this.isInitialized) {
          this.getCampaigns();
        }
      },
      deep: true
    },
    paginationArchive: {
      handler() {
        if (this.isInitialized) {
          this.getArchive();
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    name() {
      return this.$i18n.t("menu.treatCampaign");
    },
    headers() {
      return [
        {
          text: this.$i18n.t("commonTable.name"),
          align: "start",
          id: "name",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$i18n.t("coins.promoStart"),
          align: "start",
          id: "startsAt",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$i18n.t("coins.promoFinish"),
          align: "start",
          id: "endsAt",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$i18n.t("commonTable.country"),
          align: "start",
          id: "country",
          sortable: false
        },
        {
          text: this.$i18n.t("commonTable.cities"),
          align: "start",
          id: "cities",
          sortable: false,
          width: "20%"
        },
        {
          text: "Products",
          align: "start",
          id: "products",
          sortable: false,
          width: "20%"
        },
        {
          text: "",
          align: "start",
          id: "actions",
          sortable: false,
          width: "3%"
        }
      ];
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    await TreatCampaignService.init();

    this.getCampaigns();
    this.getArchive();
    this.isInitialized = true;
  },
  methods: {
    async getCampaigns() {
      const params = {
        pageSize: this.pagination.itemsPerPage,
        pageNumber: this.pagination.page - 1,
        isActive: true
      };

      this.loading = true;
      await TreatCampaignService.getCampaigns(params)
        .then(res => {
          this.dataSource = this.adjustDates(res.data?.campaigns?.results || []);
          this.total = res.data?.campaigns?.totalCount || 0;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getArchive() {
      const params = {
        pageSize: this.pagination.itemsPerPage,
        pageNumber: this.pagination.page - 1,
        isActive: false
      };

      this.loadingArchive = true;
      await TreatCampaignService.getCampaigns(params)
        .then(res => {
          this.dataSourceArchive = this.adjustDates(res.data?.campaigns?.results || []);
          this.totalArchive = res.data?.campaigns?.totalCount || 0;
        })
        .finally(() => {
          this.loadingArchive = false;
        });
    },
    adjustDates(data) {
      return data.map(item => {
        item.startsAt = formatUTCToLocal(item.startsAt);
        item.endsAt = formatUTCToLocal(item.endsAt);

        return item;
      });
    },
    getCityNames(cities) {
      return cities.map(c => c?.name).join(", ");
    },
    getProductNames(products) {
      return products.map(c => c?.name).join(", ");
    },
    onOpenFlyout(event) {
      this.$refs.addTreatCampaignFlyout.open(event);
    },
    onFlyoutSave() {
      this.pageNumber = 0;
      this.getCampaigns();
      this.getArchive();
    },
    async onDeleteCampaign(item) {
      this.loading = true;
      await this.onDelete(item)
        .then(() => {
          this.dataSource = this.dataSource.filter(dItem => dItem.id !== item.id);
          this.total = this.total - 1;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async onDeleteArchive(item) {
      this.loadingArchive = true;
      await this.onDelete(item)
        .then(() => {
          this.dataSourceArchive = this.dataSourceArchive.filter(dItem => dItem.id !== item.id);
          this.totalArchive = this.totalArchive - 1;
        })
        .finally(() => {
          this.loadingArchive = false;
        });
    },

    onDelete(item) {
      return TreatCampaignService.deleteCampaign(item?.id).then(() => {
        this.$bvToast.toast(this.$i18n.t("common.savedSuccessfully"), {
          title: this.$i18n.t("menu.coins"),
          solid: true,
          variant: "success",
          autoHideDelay: 1000
        });

        return true;
      });
    },

    formatNumber(num, decimal = 2) {
      if (!num && num !== 0) return null;
      return parseFloat(num).toFixed(decimal);
    }
  }
};
